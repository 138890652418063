<template>
    <PageLoader>
        <Header :title="$tc('setting', 2)"></Header>

        <section class="mt-10">
            <TabMenu :links="menuLinks" />
        </section>

        <section class="mt-10">
            <router-view />
        </section>
    </PageLoader>
</template>
<script>
    import TabMenu from '@/components/general/TabMenu';

    export default {
        components: {
            TabMenu,
        },
        data() {
            return {
                menuLinks: [
                    { to: { name: 'energy_markets' }, display_name: this.$tc('energy_market', 2) },
                    { to: { name: 'nace_codes' }, display_name: this.$tc('nace_code', 2) },
                    { to: { name: 'net_operators' }, display_name: this.$tc('net_operator', 2) },
                    { to: { name: 'dno_tariffs' }, display_name: this.$tc('dno_tariff', 2) },
                    { to: { name: 'solar' }, display_name: this.$tc('solar', 1) },
                    { to: { name: 'general' }, display_name: this.$tc('general', 1) },
                ],
            };
        },
    };
</script>
